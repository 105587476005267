import React from 'react'
import { graphql } from 'gatsby'

import ButtonBar from 'components/ButtonBar'

import { getPath } from '../utils'

const Audience = ({ audiences = [], selectedAudienceId = null }) => {
  const computedAudiences = [
    { id: 'all', name: 'Alle', slug: null },
    ...audiences,
  ].map(({ id, name, slug }) => ({
    id,
    to: getPath({ audience: slug }),
    children: name,
  }))

  return (
    <ButtonBar
      buttons={computedAudiences}
      selectedId={selectedAudienceId}
      minWidth={460}
    />
  )
}

export default Audience

export const query = graphql`
  fragment Audience on ContentfulAudience {
    id
    slug
    name
    seoTitle
    seoDescription {
      seoDescription
    }
  }
`
