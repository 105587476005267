import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { graphql } from 'gatsby'

import { Box, Link, Space } from 'components'

import { getPath } from '../utils'

const Tags = ({
  tags = [],
  selectedAudienceSlug = null,
  selectedTagId = null,
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Space mr={2} mb={2}>
        {tags.map(({ id, name, slug }, index) => {
          const isSelected = id === selectedTagId
          return (
            <Link
              key={id}
              as={RouterLink}
              to={getPath({
                tag: slug,
                audience: selectedAudienceSlug,
              })}
              sx={{
                display: 'inline-block',
                fontSize: 0,
                px: 2,
                py: 1,
                lineHeight: 1,
                borderRadius: 'default',
                bg: isSelected ? 'colorBlue100' : 'colorBlue5',
                color: isSelected ? 'white' : 'colorBlue100',
                textDecoration: 'none',
                zIndex: isSelected ? 5 : 1,

                '&:hover': {
                  bg: 'colorBlue10',
                  zIndex: 10,
                },
              }}
            >
              {name}
            </Link>
          )
        })}
      </Space>
    </Box>
  )
}

export default Tags

export const query = graphql`
  fragment Tag on ContentfulTag {
    id
    name
    slug
    seoTitle
    seoDescription {
      seoDescription
    }
  }
`
