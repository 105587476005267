import React from 'react'
import { graphql } from 'gatsby'

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Heading,
  Box,
  Hr,
} from 'components'
import { useLocation } from 'context/location'
import Layout from 'components/Layout'

import Article from '../components/Article'
import Audience from '../components/Audience'
import Tags from '../components/Tags'
import Sidebar from '../components/Sidebar'
import NoResults from '../components/NoResults'

const Page = ({ data, pageContext: { audienceId = 'all', tagId } }) => {
  const {
    articles: { nodes: articles },
    audiences: { nodes: audiences },
    tags: { nodes: tags },
  } = data

  const {
    seoTitle: audienceSeoTitle,
    seoDescription: { seoDescription: audienceSeoDescription } = {},
  } = audiences.find(({ id }) => id === audienceId) || {}
  const {
    seoTitle: tagSeoTitle,
    seoDescription: { seoDescription: tagSeoDescription } = {},
  } = tags.find(({ id }) => id === tagId) || {}

  const selectedAudienceName =
    audienceId && audiences.find(({ id }) => id === audienceId)?.name

  const { countryCode } = useLocation()

  const countryCodeToUse =
    countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode

  const filteredArticles = articles.filter((article) => {
    // if no countries specified, use the article
    if (
      article.countries === null ||
      (Array.isArray(article.countries) && article.countries.length === 0)
    ) {
      return true
    }

    return article.countries.includes(countryCodeToUse.toLowerCase())
  })

  return (
    <Layout
      seo={{
        title: audienceSeoTitle || tagSeoTitle || 'Ratgeber',
        description: audienceSeoDescription || tagSeoDescription,
      }}
    >
      <Section spacing="small">
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 7 / 12, 8 / 12]}>
              <Box sx={{ display: 'flex' }}>
                <Text sx={{ fontSize: 0, ml: 1 }}>Ratgeber</Text>
                <Text variant="muted" sx={{ ml: 1 }}>
                  / {selectedAudienceName}
                </Text>
              </Box>

              <Box mt={3} />

              <Audience audiences={audiences} selectedAudienceId={audienceId} />

              {filteredArticles.length === 0 ? (
                <NoResults />
              ) : (
                <>
                  <Heading.H2
                    as="h1"
                    sx={{
                      pt: 4,
                      pb: 6,
                      '@media (max-width: 320px)': {
                        fontSize: 30,
                      },
                    }}
                  >
                    Der Ratgeber für Immobilienbesitzer
                  </Heading.H2>

                  <Row
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    {filteredArticles.map((article) => (
                      <Column
                        key={article.id}
                        size={[1, null, null, 1 / 2]}
                        sx={{ mb: 8 }}
                      >
                        <Article {...article} />
                      </Column>
                    ))}
                  </Row>
                </>
              )}
            </Column>
            <Column
              id="StickySidebarBottomBoundary"
              size={[1, 1, 5 / 12, 4 / 12]}
            >
              <Box>
                <Heading.H5 sx={{ pb: 2 }}>Themenauswahl</Heading.H5>
                <Tags
                  tags={tags}
                  selectedAudienceSlug={
                    audienceId &&
                    audiences.find(({ id }) => id === audienceId)?.slug
                  }
                  selectedTagId={tagId}
                />
              </Box>

              <Hr />

              <Sidebar bottomBoundarySelector="#StickySidebarBottomBoundary"></Sidebar>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ($audienceId: String, $tagId: String) {
    articles: allContentfulArticle(
      filter: {
        node_locale: { eq: "de" }
        audience: { id: { eq: $audienceId }, name: { ne: null } }
        tags: { elemMatch: { id: { eq: $tagId }, name: { ne: null } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...Article
      }
    }
    audiences: allContentfulAudience(
      filter: { node_locale: { eq: "de" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...Audience
      }
    }
    tags: allContentfulTag(filter: { node_locale: { eq: "de" } }) {
      nodes {
        ...Tag
      }
    }
  }
`
